import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Loader from '../loader/Loader';
import { URLS } from '../../../constants/urls';
import M from 'materialize-css';

function ConVideos ( { loaderMessage, setLoaderMessage, setHasLoadedVideos, isLoading, setIsLoading, cpf, senha, idUser, tags, videos, setVideos, handleModalOpen, customStyles } ) {
    const [isVideoFormModalOpen, setIsVideoFormModalOpen] = useState(false);
    const [isEditVideoModalOpen, setIsEditVideoModalOpen] = useState(false);

    const [selectedVideoForView, setSelectedVideoForView] = useState(null);

    // Modal adição vídeos
    const [videoName, setVideoName] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [selectedTags, setSelectedTags] = useState('');

    // Modal edição vídeos
    const [selectedVideo, setSelectedVideo] = useState('');
    const [newVideoName, setNewVideoName] = useState('');
    const [newVideoDescription, setNewVideoDescription] = useState('');
    const [newVideoFile, setNewVideoFile] = useState(null);
    const [newVideoThumbnail, setNewVideoThumbnail] = useState(null);
    const [tagsFromVideo, setTagsFromVideo] = useState([]); // Armazena os produtos que o vídeo selecionado já possui
    const [tagsFromVideoFix, setTagsFromVideoFix] = useState([]);

    // Função para submissão dos vídeos com FormData utilizando axios.post
    const submitVideoFunction = async (formData) => {

        try {
            // Faz a requisição POST para o servidor PHP usando axios
            const response = await axios.post(
                URLS.CREATE_VIDEO,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Necessário para envio de arquivos
                    },
                }
            );

            if (response.data.success) {
                return {
                    status: true,
                    message: response.data.message,
                    id: response.data.id
                };
            } else {
                return {
                    status: false,
                    message: response.data.message,
                };
            }
        } catch (error) {
            console.error('Erro ao enviar vídeo:', error);
            return {
                status: false,
                message: 'Erro ao enviar dados. Verifique sua conexão ou tente novamente mais tarde.',
            };
        }
    };

    // Função para enviar os produtos associados ao vídeo
    const submitProdutoVideoFunction = async (produtoVideoData) => {
        try {
            const response = await axios.post(
                URLS.CREATE_PRODUTO_VIDEO,
                produtoVideoData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição de exclusão de um ProdutoVideo
    const deleteProdutoVideo = async (produtoVideoData) => {
        try {
            const response = await axios.post(
                URLS.DELETE_PRODUTO_VIDEO,
                produtoVideoData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Verifica se a resposta indica sucesso
            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição de todos os vídeos
    const fetchVideos = async () => {
        setLoaderMessage("Carregando testes");

        try {
            const response = await fetch(URLS.READ_ALL_VIDEO, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idUser: idUser,
                    senha: senha,
                }),
            });

            // Tente converter a resposta para JSON
            const data = await response.json();

            // Verifique o sucesso da resposta
            if (data.success) {
                const parsedVideos = data.videos.map((video) => ({
                    idVideo: video.id,
                    nome: video.nome,
                    descricao: video.descricao,
                    link: video.link,
                    thumbnail: video.thumbnail,
                }));

                // Atualize o estado dos vídeos
                setVideos(parsedVideos);
                setHasLoadedVideos(true);
            } else {
                console.error(data.message);
            }

        } catch (error) {
            console.error("Erro ao buscar vídeos:", error);
        }
    };

    // Função para fazer a requisição dos produtos relacionados a um vídeo
    const fetchProductsByVideo = async (idVideo) => {
        try {
            const response = await fetch(URLS.READ_PRODUTO_VIDEO_BY_VIDEO_ID, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idVideo: idVideo,
                    idUser: idUser,
                    senha: senha,
                }),
            });

            // Verifica se a resposta tem sucesso (status 200-299)
            if (!response.ok) {
                console.error(`Erro na requisição: ${response.status} - ${response.statusText}`);
                return [];
            }

            // Verifica se a resposta contém algo antes de tentar convertê-la em JSON
            const textResponse = await response.text();
            if (!textResponse) {
                console.error("Resposta vazia do servidor.");
                return [];
            }

            // Tente converter a resposta para JSON
            const data = JSON.parse(textResponse);

            // Verifique o sucesso da resposta
            if (data.success) {
                // Mapeia os produtos retornando apenas os IDs dos produtos
                const productIds = data.produtosVideo.map((produto) => produto.idProduto);

                // Atualizando o estado tagsFromTest com os IDs dos produtos
                setTagsFromVideo(productIds);
                setTagsFromVideoFix(productIds);

                return productIds;
            } else {
                console.error(data.message);
                return [];
            }
        } catch (error) {
            console.error("Erro ao buscar produtos relacionados ao vídeo:", error);
            return [];
        }
    };

    // Função para fechar o modal de adição de vídeos
    const handleCloseVideoFormModal = () => {
        setIsVideoFormModalOpen(false);
        // Limpar campos após fechar o modal
        setVideoName('');
        setVideoDescription('');
        setVideoFile(null);
        setThumbnailFile(null);
        setSelectedTags([]);
    };

    const handleCloseEditVideoModal = () => {
        setIsEditVideoModalOpen(false);
    
        // Reiniciar os valores dos inputs
        setSelectedVideo('');
        setNewVideoName('');
        setNewVideoDescription('');
        setNewVideoFile(null);
        setNewVideoThumbnail(null);
        setTagsFromVideo([]);
        setTagsFromVideoFix([]);
    };

    const handleVideoFileChange = (e) => {
        setVideoFile(e.target.files[0]); // Armazena o arquivo de vídeo no estado
    };
    
    const handleThumbnailFileChange = (e) => {
        setThumbnailFile(e.target.files[0]); // Armazena o arquivo de thumbnail no estado
    };    

    const handleTagSelection = (isChecked, idTag) => {
        if (isChecked) {
            setSelectedTags([...selectedTags, idTag]);
        } else {
            setSelectedTags(selectedTags.filter(tagId => tagId !== idTag));
        }

        console.log(selectedTags);
    };

    // Função para submissão do formulário de vídeos
    const onSubmitVideo = async (e) => {
        e.preventDefault();

        // Cria um objeto FormData para envio dos dados e arquivos
        const formData = new FormData();
        formData.append('nome', videoName);
        formData.append('descricao', videoDescription);
        formData.append('video', videoFile); // Arquivo de vídeo
        formData.append('thumbnail', thumbnailFile); // Arquivo de thumbnail
        formData.append('idUser', idUser);
        formData.append('senha', senha);

        setIsLoading(true);
        setLoaderMessage("Enviando dados do vídeo");

        try {
            const result = await submitVideoFunction(formData);

            if (result.status) {
                const videoId = result.id;

                setLoaderMessage("Associando vídeos aos produtos");

                // Envia cada tag (produto) associado ao vídeo criado
                for (const idProduto of selectedTags) {

                    const produtoVideoData = {
                        idVideo: videoId,
                        idProduto: idProduto,
                        idUser: idUser,
                        senha: senha,
                    };

                    await submitProdutoVideoFunction(produtoVideoData);
                }

                // Atualiza a lista de vídeos
                await fetchVideos();

                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });

            } else {
                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });
            }

        } catch (error) {
            console.error('Erro ao adicionar vídeo:', error);
            alert('Erro ao adicionar vídeo. Verifique a conexão.');
        }

        setLoaderMessage('');
        handleCloseVideoFormModal();
        setIsLoading(false);
    };

    // Funções para manipular arquivos do vídeo
    const handleNewVideoFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewVideoFile(file);  // Armazenar o arquivo diretamente
        }
    };

    // Função para seleção de vídeo e atualização dos estados relevantes para o modal de edição
    const handleVideoSelection = async (videoId) => {
        setSelectedVideo(videoId);
        setTagsFromVideo([]); // Limpar o estado de produtos anteriores
        setTagsFromVideoFix([]);

        setIsLoading(true);
        setLoaderMessage("Procurando dados associados");

        // Aguarda o retorno da função fetchProductsByTest
        const products = await fetchProductsByVideo(videoId);

        setLoaderMessage('');
        setIsLoading(false);

        // Agora você pode garantir que 'products' está disponível
        products.forEach((productId) => {
            console.log(productId);  // Irá exibir o id de cada produto
        });
    };

    const handleNewThumbnailFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewVideoThumbnail(file);  // Armazenar o arquivo diretamente
        }
    };

    // Função para lidar com a seleção das tags
    const handleEditTagSelection = (isChecked, idTag) => {
        let updatedTags;

        if (isChecked) {
            // Se o checkbox foi marcado, adiciona o idTag ao array
            updatedTags = [...tagsFromVideo, idTag];
        } else {
            // Se o checkbox foi desmarcado, remove o idTag do array
            updatedTags = tagsFromVideo.filter(tagId => tagId !== idTag);
        }

        // Atualiza o estado com os ids selecionados
        setTagsFromVideo(updatedTags);
        console.log(tagsFromVideo);
    }; 

    const onEditVideo = async (e) => {
        e.preventDefault();

        const videoToEdit = videos.find(video => video.idVideo === selectedVideo);

        // Criar um FormData para enviar arquivos junto com os outros dados
        const formData = new FormData();
        formData.append('id', videoToEdit.idVideo);
        formData.append('idUser', idUser);
        formData.append('senha', senha);
        formData.append('nome', newVideoName);
        formData.append('descricao', newVideoDescription);

        // Adicionar arquivos ao FormData se estiverem presentes
        if (newVideoFile) {
            formData.append('video', newVideoFile);
        }
        if (newVideoThumbnail) {
            formData.append('thumbnail', newVideoThumbnail);
        }

        setIsLoading(true);
        setLoaderMessage("Enviando dados do vídeo");

        try {
            const response = await fetch(URLS.UPDATE_VIDEO, {
                method: 'POST',
                body: formData,  // Enviar FormData em vez de JSON
            });

            const data = await response.json();

            if (data.success) {
                // IDs que devem ser criados (estão em tagsFromVideo, mas não em tagsFromVideoFix)
                const toCreate = tagsFromVideo.filter(id => !tagsFromVideoFix.includes(id));
                // IDs que devem ser excluídos (estão em tagsFromVideoFix, mas não em tagsFromVideo)
                const toDelete = tagsFromVideoFix.filter(id => !tagsFromVideo.includes(id));

                if(toCreate.length > 0){
                    setLoaderMessage("Associando novos produtos");

                    // Envia cada tag (produto) associado ao teste criado
                    for (const idProduto of toCreate) {
                        
                        const produtoVideoData = {
                            idVideo: videoToEdit.idVideo,
                            idProduto: idProduto,
                            idUser: idUser,
                            senha: senha,
                        };

                        await submitProdutoVideoFunction(produtoVideoData); // Cria os registros
                    }
                }
                
                if(toDelete.length > 0){
                    setLoaderMessage("Desassociando produtos");

                    // Envia cada tag (produto) associado ao teste criado
                    for (const idProduto of toDelete) {
                        
                        const produtoVideoData = {
                            idVideo: videoToEdit.idVideo,
                            idProduto: idProduto,
                            idUser: idUser,
                            senha: senha,
                        };

                        await deleteProdutoVideo(produtoVideoData); // Deleta os registros
                    }
                }

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
                
                // Recarregar os vídeos atualizados
                fetchVideos();
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error("Erro ao atualizar o vídeo:", error);
        }

        setLoaderMessage('');
        handleCloseEditVideoModal();
        setIsLoading(false);
    };

    const onDeleteVideo = async () => {
        if(!selectedVideo){
            M.toast({
                html: 'Selecione um vídeo!',
                classes: 'toast_custom'
            });

            return
        }

        const videoToEdit = videos.find(video => video.idVideo === selectedVideo);

        if (!videoToEdit) {
            M.toast({
                html: 'Vídeo não encontrado para exclusão.',
                classes: 'toast_custom'
            });

            return;
        }
    
        // Preparando os dados para a requisição de exclusão
        const deleteData = {
            id: videoToEdit.idVideo,
            idUser: idUser,
            senha: senha,
            auth: 'C$CKMkyaYNq5Dx?s' // Token de autenticação para a API
        };
    
        setIsLoading(true);
        setLoaderMessage("Excluindo teste");

        try {
            // Fazendo a requisição para excluir o vídeo no backend
            const response = await fetch(URLS.DELETE_VIDEO, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteData),
            });
    
            const data = await response.json();
            
            if (data.success) {
                setLoaderMessage("Desassociando produtos");

                // Envia cada tag (produto) associado ao vídeo
                for (const idProduto of tagsFromVideoFix) {
                    
                    const produtoVideoData = {
                        idVideo: videoToEdit.idVideo,
                        idProduto: idProduto,
                        idUser: idUser,
                        senha: senha,
                    };

                    await deleteProdutoVideo(produtoVideoData); // Deleta os registros
                }

                await fetchVideos();

                // Log para fins de depuração
                console.log(`O CPF "${cpf}" excluiu o vídeo "${selectedVideo}"`);

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error('Erro ao excluir produto:', error);
        }

        setLoaderMessage('');
        setIsLoading(false);
        handleCloseEditVideoModal();
    }

    useEffect(() => { // useEffect para que todos os vetores com os dados dos itens sejam carregados ao montar o componente
        fetchVideos();
    }, []); 

    return (
        <div className='ConVideos'>

            {/* Div Vídeos */}
            <div className='main_div_conts div_cinza'>

                {/* Desktop */}
                <div className='row hide-on-small-only'>
                    <div className='col s6'>
                        <h3 className='text_title_conts'>Vídeos:</h3>
                    </div>
                    <div className='col s6 row div_btn_cards'>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsVideoFormModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">add</i></button>
                        </div>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsEditVideoModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">create</i></button> 
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className='row hide-on-med-and-up'>
                    <div className='col l6 m6 s12 div_title_conts_mobile'>
                        <h3 className='text_title_conts'>Vídeos:</h3>
                    </div>
                    <div className='col l6 m6 s12 row div_btn_cards_mobile'>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsVideoFormModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">add</i></button>
                        </div>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsEditVideoModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">create</i></button> 
                        </div>
                    </div>
                </div>

                <div className="tag_section row">
                    {videos.length === 0 ? (
                        <div className="col">
                            <p className='no_text_conts flow-text'>Parece que ainda não temos vídeos, adicione um para começar!</p>
                        </div>
                    ) : (
                        videos.map((video, index) => (
                            <div
                                key={index}
                                className="tag_item col"
                                style={{ backgroundColor: '#041838', cursor: 'pointer' }}
                                onClick={() => setSelectedVideoForView(video)}
                            >
                                <p className='text_tag white-text'>{video.nome}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* ----------------------------------- Seção dos Modais ----------------------------------- */}

            {/* Modal de adição de vídeo */}
            <Modal
                isOpen={isVideoFormModalOpen}
                onRequestClose={handleCloseVideoFormModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Adição de Vídeos"
            >
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onSubmitVideo}>
                        <div className="row">
                            {/* Checkbox para selecionar as tags */}
                            <div className="col s12 div_dropdown_input">
                                <label>Selecione as os produtos</label>
                                <div className="checkbox-group">
                                    {tags.map((tag, index) => (
                                        <label key={index}>
                                            <input
                                                className='filled-in checkbox_custom'
                                                type="checkbox"
                                                value={tag.idTag}
                                                checked={selectedTags.includes(tag.idTag)}
                                                onChange={(e) => handleTagSelection(e.target.checked, tag.idTag)}
                                            />
                                            <span className='text_checkbox'>{tag.nome}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div className="col s12">
                                <label htmlFor="videoName">Nome do Vídeo</label>
                                <input 
                                    id="videoName" 
                                    type="text" 
                                    className="validate" 
                                    required 
                                    value={videoName} 
                                    onChange={(e) => setVideoName(e.target.value)} 
                                    autoComplete="off"
                                />
                            </div>
                            <div className="col s12">
                                <label htmlFor="videoDescription">Descrição do Vídeo</label>
                                <textarea 
                                    id="videoDescription" 
                                    className="materialize-textarea" 
                                    required 
                                    value={videoDescription} 
                                    onChange={(e) => setVideoDescription(e.target.value)} 
                                    autoComplete="off"
                                ></textarea>
                            </div>

                            <div className="col s12 div_input_img file-field input-field">
                                <div className='btn buttons_forms'>
                                    <span>Vídeo</span>
                                    <input 
                                        id="videoFile" 
                                        type="file"
                                        required 
                                        accept="video/*"
                                        onChange={handleVideoFileChange} // Função para capturar o arquivo de vídeo
                                    />
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text"></input>
                                </div>
                            </div>

                            <div className="col s12 div_input_img file-field input-field">
                                <div className='btn buttons_forms'>
                                    <span>Thumbnail</span>
                                    <input 
                                        id="thumbnailFile" 
                                        type="file"
                                        required 
                                        accept="image/*"
                                        onChange={handleThumbnailFileChange} // Função para capturar o arquivo de thumbnail
                                    />
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text"></input>
                                </div>
                            </div>

                            <div className="col s6">
                                <button 
                                    onClick={handleCloseVideoFormModal} 
                                    className="waves-effect waves-light waves-white btn left buttons_forms"
                                >
                                    Fechar
                                </button>
                            </div>
                            <div className="col s6">
                                <button className="waves-effect waves-light waves-white btn right buttons_forms" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal de edição de vídeo */}
            <Modal
                isOpen={isEditVideoModalOpen}
                onRequestClose={handleCloseEditVideoModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edição de Vídeos"
            >
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onEditVideo}>
                        <div className="row">
                            {/* Input de seleção do vídeo a ser editado */}
                            <div className="col s12 div_dropdown_input">
                                <select
                                    className="browser-default tag_dropdown_input"
                                    id="selectVideo"
                                    value={selectedVideo}
                                    onChange={(e) => handleVideoSelection(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Selecione um vídeo</option>
                                    {videos.map((video, index) => (
                                        <option key={index} value={video.idVideo}>{video.nome}</option>
                                    ))}
                                </select>
                            </div>

                            { selectedVideo && (
                                <>
                                    {/* Checkbox para as tags */}
                                    <div className="col s12">
                                        <label>Selecione os novos produtos</label>
                                        <div className="checkbox-group">
                                            {tags.map((tag, index) => (
                                                <label key={index}>
                                                    <input
                                                        className='filled-in checkbox_custom'
                                                        type="checkbox"
                                                        value={tag.idTag}
                                                        checked={tagsFromVideo.includes(tag.idTag)}
                                                        onChange={(e) => handleEditTagSelection(e.target.checked, tag.idTag)}
                                                    />
                                                    <span className='text_checkbox'>{tag.nome}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Input para o novo nome do vídeo */}
                            <div className="col s12">
                                <label htmlFor="editVideoName">Novo Nome do Vídeo</label>
                                <input
                                    id="editVideoName"
                                    type="text"
                                    className="validate"
                                    required
                                    value={newVideoName}
                                    onChange={(e) => setNewVideoName(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>

                            {/* Input para a nova descrição do vídeo */}
                            <div className="col s12">
                                <label htmlFor="editVideoDescription">Nova Descrição</label>
                                <textarea
                                    id="editVideoDescription"
                                    className="materialize-textarea"
                                    required
                                    value={newVideoDescription}
                                    onChange={(e) => setNewVideoDescription(e.target.value)}
                                    autoComplete="off"
                                ></textarea>
                            </div>

                            {/* Input para o novo arquivo de vídeo */}
                            <div className="col s12 div_input_img file-field input-field">
                                <div className='btn buttons_forms'>
                                    <span>Novo Arquivo de Vídeo</span>
                                    <input 
                                        id="editVideoFile"
                                        type="file"
                                        required 
                                        accept="video/*"
                                        onChange={handleNewVideoFileChange}
                                    />
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text"></input>
                                </div>
                            </div>

                            {/* Input para o novo arquivo de thumbnail */}
                            <div className="col s12 div_input_img file-field input-field">
                                <div className='btn buttons_forms'>
                                    <span>Novo Arquivo de Thumbnail</span>
                                    <input 
                                        id="editVideoThumbnail"
                                        type="file"
                                        required 
                                        accept="image/*"
                                        onChange={handleNewThumbnailFileChange}
                                    />
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text"></input>
                                </div>
                            </div>

                            {/* Botões de Excluir, Fechar e Confirmar */}
                            <div className="col s12">
                                <button type="button" onClick={onDeleteVideo} className="waves-effect waves-light waves-white btn red buttons_forms">
                                    Excluir
                                </button>
                            </div>

                            <div className="col s6">
                                <button onClick={handleCloseEditVideoModal} className="waves-effect waves-light waves-white btn left buttons_forms">
                                    Fechar
                                </button>
                            </div>

                            <div className="col s6">
                                <button className="waves-effect waves-light waves-white btn right buttons_forms" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal de visualização de vídeo */}
            <Modal
                isOpen={selectedVideoForView !== null}
                onRequestClose={() => setSelectedVideoForView(null)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Visualização de Vídeo"
            >
                {selectedVideoForView && (
                    <div className='div_forms'>
                        <h4 className='text_title_show_modal'>Detalhes do Vídeo</h4>
                        <p className='text_show_modal'><strong>Nome:</strong> {selectedVideoForView.nome}</p>
                        <p className='text_show_modal'><strong>Descrição:</strong> {selectedVideoForView.descricao}</p>
                        
                        <h5 className='text_subtitle_show_modal'>Player do Vídeo:</h5>
                        <div className='video_player'>
                            <video controls>
                                <source src={selectedVideoForView.link} type="video/mp4" />
                                Seu navegador não suporta a reprodução de vídeos.
                            </video>
                        </div>

                        <h5 className='text_subtitle_show_modal'>Thumbnail:</h5>
                        <div className='video_thumbnail'>
                            <img src={selectedVideoForView.thumbnail} alt="Thumbnail do vídeo" style={{ maxWidth: '100%' }} />
                        </div>

                        <button
                            onClick={() => setSelectedVideoForView(null)}
                            className="waves-effect waves-light btn buttons_show_modal"
                        >
                            Fechar
                        </button>
                    </div>
                )}
            </Modal>

        </div>
    );
}

export default ConVideos;