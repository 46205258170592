import React, { useEffect, useState } from 'react';
import './Home.css';
import ConProdutos from '../content/con_produtos/ConProdutos';
import ConTestes from '../content/con_testes/ConTestes';
import ConVideos from '../content/con_videos/ConVideos';
import ConUsuarios from '../content/con_usuarios/ConUsuarios';
import ConInstitucional from '../content/con_institucional/ConInstitucional';
import Loader from '../content/loader/Loader';
import logo from '../../images/logo_sante.png';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        overflowX: 'hidden',
        overflowY: 'auto',
        zIndex: 1000
    },
    overlay: {
        zIndex: 999
    }
};

function Home( { cpf, senha, idUser, handleLogout } ) {
    const [control, setControl] = useState('produtos'); // Controla a seção atual: 'produtos', 'usuarios', 'institucional'
    const [previousControl, setPreviousControl] = useState('produtos');
    const [tags, setTags] = useState([]);
    const [tests, setTests] = useState([]);
    const [videos, setVideos] = useState([]);
    const [institucionais, setInstitucionais] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [loaderMessage, setLoaderMessage] = useState('');

    const [hasLoadedTags, setHasLoadedTags] = useState(false);
    const [hasLoadedTests, setHasLoadedTests] = useState(false);
    const [hasLoadedVideos, setHasLoadedVideos] = useState(false);

    const MINIMUM_LOADING_TIME = 0; // Tempo mínimo de exibição do loader em milissegundos

    const handleModalOpen = (modalSetter) => {
        modalSetter(true);
    };

    // Função para alternar o conteúdo e definir a direção da animação
    const handleSetControl = (newControl) => {
        setPreviousControl(control);
        setControl(newControl);
    };

    // Função para determinar a animação com base no controle anterior e atual
    const getAnimationClass = (current, previous) => {
        if ((previous === 'produtos' && (current === 'usuarios' || current === 'institucional')) ||
            (previous === 'usuarios' && current === 'institucional')) {
            return 'slide-left';
        } else if ((previous === 'usuarios' && current === 'produtos') ||
                   (previous === 'institucional' && (current === 'produtos' || current === 'usuarios'))) {
            return 'slide-right';
        } else {
            return '';
        }
    };

    // Detectar gestos de swipe
    const handleSwipeLeft = () => {
        if (control === 'produtos') {
            handleSetControl('usuarios');
        } else if (control === 'usuarios') {
            handleSetControl('institucional');
        }
    };

    const handleSwipeRight = () => {
        if (control === 'institucional') {
            handleSetControl('usuarios');
        } else if (control === 'usuarios') {
            handleSetControl('produtos');
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // Permite que funcione no desktop com o mouse também
    });

    useEffect(() => {
        // Verifica se todas as funções de carregamento terminaram
        const loadingTimeout = setTimeout(() => {
            if (hasLoadedTags && hasLoadedTests && hasLoadedVideos) {
                setIsLoading(false);  // Desativa o loader após o tempo mínimo
                setLoaderMessage('');
            }
        }, MINIMUM_LOADING_TIME);

        return () => clearTimeout(loadingTimeout); // Limpa o timeout quando o componente é desmontado
    }, [hasLoadedTags, hasLoadedTests, hasLoadedVideos]);   

    return (
        <div className="Home" {...handlers}> {/* Adiciona os handlers de swipe à div principal */}

            {/* Navbar */}
            <div className='div_back'>
                <div className='row main_navbar'>
                    <div className='col s6 m6 l6 div_img_logo_navbar'>
                        <a href='#'><img className='img_logo_navbar' src={logo} alt="Logo" /></a>
                    </div>

                    <div className='col s6 m6 l6 div_text_navbar'>
                        <a className='text_navbar' href='#' onClick={handleLogout}>Sair</a>
                    </div>
                </div>

                <div className='row sub_navbar'>
                    <div className={`div_text_sub_navbar ${control === 'produtos' ? 'active_navbar' : ''}`} onClick={() => handleSetControl('produtos')}>
                        <a className={`text_sub_navbar white-text ${control === 'produtos' ? 'active_navbar_text' : ''}`} href='#'>Produtos</a>
                    </div>
                    <div className={`div_text_sub_navbar ${control === 'usuarios' ? 'active_navbar' : ''}`} onClick={() => handleSetControl('usuarios')}>
                        <a className={`text_sub_navbar white-text ${control === 'usuarios' ? 'active_navbar_text' : ''}`} href='#'>Usuários</a>
                    </div>
                    <div className={`div_text_sub_navbar ${control === 'institucional' ? 'active_navbar' : ''}`} onClick={() => handleSetControl('institucional')}>
                        <a className={`text_sub_navbar white-text ${control === 'institucional' ? 'active_navbar_text' : ''}`} href='#'>Institucional</a>
                    </div>
                </div>
            </div>

            {/* Condicional para mostrar o loader */}
            {isLoading && (
                <Loader message={loaderMessage}></Loader>
            )}

            {/* Transição de slider entre conteúdo */}
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={control}
                    timeout={300}
                    classNames={getAnimationClass(control, previousControl)} // Classe de animação
                >
                    <div className='main_div_home'>
                        {control === 'produtos' ? (
                            <>
                                <ConProdutos
                                    loaderMessage={loaderMessage}
                                    setLoaderMessage={setLoaderMessage}
                                    setHasLoadedTags={setHasLoadedTags}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    cpf={cpf}
                                    senha={senha}
                                    idUser={idUser}
                                    tags={tags}
                                    setTags={setTags}
                                    handleModalOpen={handleModalOpen}
                                    customStyles={customStyles}
                                />

                                <ConTestes
                                    loaderMessage={loaderMessage}
                                    setLoaderMessage={setLoaderMessage}
                                    setHasLoadedTests={setHasLoadedTests}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    cpf={cpf}
                                    senha={senha}
                                    idUser={idUser}
                                    tags={tags}
                                    tests={tests}
                                    setTests={setTests}
                                    handleModalOpen={handleModalOpen}
                                    customStyles={customStyles}
                                />

                                <ConVideos
                                    loaderMessage={loaderMessage}
                                    setLoaderMessage={setLoaderMessage}
                                    setHasLoadedVideos={setHasLoadedVideos}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    cpf={cpf}
                                    senha={senha}
                                    idUser={idUser}
                                    tags={tags}
                                    videos={videos}
                                    setVideos={setVideos}
                                    handleModalOpen={handleModalOpen}
                                    customStyles={customStyles}
                                />
                            </>
                        ) : control === 'usuarios' ? (
                            <>
                                <ConUsuarios
                                    loaderMessage={loaderMessage}
                                    setLoaderMessage={setLoaderMessage}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    cpf={cpf}
                                    senha={senha}
                                    idUser={idUser}
                                    tags={tags}
                                    tests={tests}
                                    handleModalOpen={handleModalOpen}
                                    customStyles={customStyles}
                                />
                            </>
                        ) : control === 'institucional' ? (
                            <>
                                <ConInstitucional
                                    loaderMessage={loaderMessage}
                                    setLoaderMessage={setLoaderMessage}
                                    isLoading={isLoading}
                                    setIsLoading={setIsLoading}
                                    cpf={cpf}
                                    senha={senha}
                                    idUser={idUser}
                                    institucionais={institucionais}
                                    setInstitucionais={setInstitucionais}
                                    handleModalOpen={handleModalOpen}
                                    customStyles={customStyles}
                                />
                            </>
                        ) : null}
                    </div>
                </CSSTransition>
            </SwitchTransition>

        </div>
    );
}

export default Home;